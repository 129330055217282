.address {
	border: none;
	border-radius: 10px;
	background: #fff;
	flex: 1;
}
.address_head {
	border-radius: 10px 10px 0px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--ltWhite);
	padding: 12px 10px;
	border-bottom: 1px solid var(--ltGreyscale300);
	span {
		display: flex;
		align-items: center;
		gap: 5px;
		color: #1da099;
		cursor: pointer;
	}
}
.address_head.light {
	background: #f7f7f7;
}
.address_info {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px;
}
.address_text {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	color: #1da099;
}
